<template >
  <ion-page>
    <Header type="0" title="入库接收"> </Header>
    <div v-show="isListType">
      <div class="root" >
        <div class="item" v-show="radioId == 2">
          <div class="left">
            <span class="required">&nbsp;&nbsp;</span
            >任务单号
          </div>
          <div class="right">
            <ion-input
              :disabled="true"
              v-model="stageCode"
              :placeholder="'任务单号' "
            ></ion-input>
            <!-- <ion-text v-if="radioId == 2" @click="transfer">扫一扫</ion-text> -->
          </div>
        </div>
        <div class="item">
          <div class="left">
            <span class="required">*</span
            >{{ radioId == 2 ? "采购单号" : "试制任务单号" }}
          </div>
          <div class="right">
            <ion-input
              :disabled="true"
              @click="getTaskCode"
              v-model="taskCode"
              :placeholder="radioId == 2 ? '请输入采购单号' : '请输入任务单号'"
            ></ion-input>
            <!-- <ion-text v-if="radioId == 2" @click="transfer">扫一扫</ion-text> -->
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>入库类型</div>
          <div class="right">
            <div style="margin-right: 10px">
              <input type="radio" value="2" v-model="radioId" />
              <span class="checkboxText">采购入库</span>
            </div>
            <div>
              <input type="radio" value="1" v-model="radioId" /><span
                class="checkboxText"
                >自制件入库</span
              >
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>是否立即出库</div>
          <div class="right">
            <div style="margin-right: 10px">
              <input type="radio" value="1" v-model="isDelivery" />
              <span class="checkboxText">是</span>
            </div>
            <div>
              <input type="radio" value="0" v-model="isDelivery" />
              <span class="checkboxText">否</span>
            </div>
          </div>
        </div>
        <div class="item" v-if="radioId == 1">
          <ion-button class="selectBtn" @click="selected">选择零件</ion-button>
        </div>
      </div>
      </div>
    <ion-content scroll-y="true" class="content" id="ProjectMainProjectList" >
      

      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div> 
      <template v-if="isListType && radioId == 2">
        <div class="app-container card-content" v-for="(item, idx) in tableList" :key="idx">
        <i
          class="iconfont icon-touxiangshanchu delPart"
          @click.stop="delPart(item, idx)"
          v-if="radioId == 1"
        ></i>
        <div>
          <div>
          <ion-checkbox color="light" v-model="item.checked"></ion-checkbox>
          <ion-text class="questionTitle">{{ item.partCode }}</ion-text>
        </div>
        <div v-show="item.checked" style="padding:0">
          <div class="item-label" style="padding:0">接收数量</div>
            <ion-input
              type="number"
              v-model.number="item.arrivalNum"
              @ionChange="changeNum(item, idx)"
              style="text-align: right; --padding-end: 0;width:5rem;height:1rem"
            ></ion-input>
        </div>
        <div>
        <ion-img
          class="imgIcon"
          :src="require('@/assets/images/identifier.png')"
        ></ion-img>
          <ion-text class="identifier">{{ item.partVersion }}</ion-text>
        </div>
        </div>
      </div>
      </template>
      <template v-else>
        <div class="app-container" v-for="(item, idx) in tableList.filter(x=> {return radioId == 2?x.checked == true:true })" :key="idx">
        <i
          class="iconfont icon-touxiangshanchu delPart"
          @click.stop="delPart(item, idx)"
          v-if="radioId == 1"
        ></i>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
        >
          <div class="item-label">{{ item.partCode }}</div>
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        <div class="item">
          <div class="item-label">数量</div>
          <div class="item-content">{{ item.eachNum || item.trialNum }}</div>
        </div>
        <div class="item">
          <div class="item-label">接收数量</div>
          <div class="item-content">
            <ion-input
              v-model.number="item.arrivalNum"
              @ionChange="changeNum(item, idx)"
              placeholder="请输入接收数量"
              style="text-align: right; --padding-end: 0"
            ></ion-input>
          </div>
        </div>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 25px"
        >
          <div class="item-label">库位</div>
          <div class="item-content">
            <ion-text @click.stop="storeReview(item, idx)">
              <span class="addBorderRight">{{ item.localeCodes }}</span>
            </ion-text>
            <ion-icon
              @click.stop="addTaskHandler(item, idx)"
              class="icon"
              :icon="qrCode"
            ></ion-icon>
          </div>
        </div>
      </div>
      </template>


      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>
    <div ref="appModal" class="canvasContent"></div>
    <template v-if="hidshow">
      <ion-footer v-if="isListType && radioId == 2">
        <ion-button expand="block" @click="selectOK">确认</ion-button>
      </ion-footer>
      <ion-footer v-else>
        <ion-button
          expand="block"
          v-if="tableList.length > 0"
          @click="marking"
          color="success"
          >打码</ion-button
        >
        <ion-button expand="block" @click="syncStore">同步库位</ion-button>
        <ion-button expand="block" @click="commit">提交</ion-button>
        <ion-button expand="block" @click="RselectOK" v-if="radioId == 2">重新选择</ion-button>
      </ion-footer>
    </template>

    
    
    <StoreChoose
      :ids="stockLoacleIds"
      :stocks="stocks"
      @choose="choose"
      @transfer="transfer"
    ></StoreChoose>
    <search-task-code :id="radioId" @chooseCode="chooseCode"></search-task-code>
    <scanner v-if="showScan" @closeScan="closeScan"></scanner>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  menuController,
  loadingController,
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import { qrCode } from "ionicons/icons";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import StoreChoose from "./storeChoose.vue";
import SearchBar from "@/components/SearchBar.vue";
import storeReview from "./storeReview.vue";
import {
  createStorageBill,
  storagePublish,
  getStorageItem,
  createTwoCode,
  getPartBySelect,
  deletePartBySelect,
} from "@/api/storeManagement/warehouseReceiving.js";
import searchTaskCode from "./searchTaskCode.vue";
import scanner from "@/components/cordova/qrScanner.vue";
import receiveSelectParts from "./receiveSelectParts.vue";
import picModel from "./picModel.vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
    Header,
    CardLoading,
    ionAlert,
    StoreChoose,
    SearchBar,
    searchTaskCode,
    scanner,
    receiveSelectParts,
    storeReview,
    picModel,
  },
  watch: {
    radioId: function (newVal, oldVal) {
      this.tableList = [];
      this.stageId = "";
      this.stockLoacleIds = "";
      this.idx = 0;
      this.taskCode = "";
      this.stageCode = "";
      this.contents = {};
    },
  },
  data() {
    return {
      isListType:true, // true 简介 false 详情
      qrCode,
      tableList: [],
      islist: false, // isloading
      isOpenRef: false, // 确认框
      innerId: "",
      stockLoacleIds: "",
      idx: 0,
      radioId: 2,
      taskCode: "", //任务单号
      stageCode:"",
      contents: {},
      showScan: false,
      stageId: "",
      markList: [],
      parData: {},
      buyer: "",
      stocks: "",
      isDelivery: 0, //是否立即出库
      docmHeight:0,  //默认屏幕高度
      hidshow:true  //显示或者隐藏footer
    };
  },
  mounted() {

    this.docmHeight = document.documentElement.clientHeight;//获取当前屏幕高度
    window.onresize = () => {//屏幕高度变化时判断
      return (() => {
        let showHeight = document.body.clientHeight;
        this.hidshow = this.docmHeight > showHeight ? false : true;
      })();
    };
  },
  async created() {
    const { data } = await createStorageBill();
    this.innerId = data.data.resultData.id;
  },

  methods: {

    selectOK(){
      let aalist = this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true  })
      if (aalist.length == 0) {
        Utils.presentToastTopWarning("无可接收零件", "danger");
        return false;
      }
      this.isListType = false;
    },
    RselectOK(){
      this.isListType = true;
    },
    async pushOptions() {
      var str = "";
      this.markList.forEach((e, idx) => {
        str += `<div class="app-container">
                   <canvas class="imgCanvas" id="canvas${idx}"></canvas>
                </div>`;
      });

      this.$refs["appModal"].innerHTML = str;
      this.$forceUpdate();
      var _this = this;
      this.$nextTick(() => {
        for (var i = 0; i < _this.markList.length; i += 2) {
          (function (j) {
            var canvas = document.getElementById(`canvas${j}`);
            var context = canvas.getContext("2d");
            canvas.width = 1248;
            canvas.height = 350;
            context.fillStyle = "#fff";
            context.fillRect(0, 0, canvas.width, canvas.height);
            _this.drawCanvas(canvas, context, j, 10);
            if (j + 1 < _this.markList.length) {
              _this.drawCanvas(canvas, context, j + 1, 525);
            }
          })(i);
        }
      });
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });

      await loading.present();
      setTimeout(() => {
        loading.dismiss();
        Utils.presentToastTopWarning("打印成功", "success");
        _this.printCanvas();
      }, 1000);
    },
    test() {
      var _this = this;
      var options = {
        font: {
          size: 22,
          italic: true,
          align: "center",
        },
        header: {
          height: "6cm",
          label: {
            text: "\n\nDie Freuden",
            font: {
              bold: true,
              大小: 37,
              对齐: "center",
            },
          },
        },
        footer: {
          height: "4cm",
          label: {
            text: "Johann Wolfgang von Goethe, 1749-1832, deutscher Dichter, Naturforscher",
            字体: { align: "center" },
          },
        },
      };
      var str = "";
      var list = this.tableList.filter((e) => {
        return e.isCheck == 0 && e.arrivalNum != 0;
      }).filter(x=> {return this.radioId == 2?x.checked == true:true  });
      if (list.length == 0) {
        _this.$router.go(-1);
        return false;
      }
      list.forEach((e, i) => {
        str += ` <tr>
        <td >${i + 1}</td>
        <td >${e.partCode}</td>
        <td >${e.partNameCN || ""}</td>
        <td >${e.trialNum || 0}</td>
        <td >${e.inNum || 0}</td>
        <td >${this.buyer || ""}</td>
          <td >${e.localeCodes || ""}</td>
      </tr>`;
      });
      window.PrinterPlugin.print(
        `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body>
  <div style="padding:1px;break-after: page;position:relative">
    <div style="${this.title().title}">
    <div style="${
      this.title().header
    }left: 40%; top: 5pt;font-size: 22pt; ">入库验收单</div>  
    <div style="${
      this.title().header
    }left: 60%; top: 40pt;font-size: 16pt; ">验收单号：${
          this.parData.code || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 70pt;font-size: 16pt;">试制任务单：${
          this.parData.stageCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left:20pt; top: 90pt;font-size: 16pt;">试制提单人：${
          this.parData.submitPerson || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 110pt;font-size: 16pt;">提单人电话：${
          this.parData.submitPersonPhone || ""
        }</div>  
    <div style="${
      this.title().header
    }left:270pt; top: 70pt;font-size: 16pt;">采购申请号：${
          this.parData.purchaseCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 90pt;font-size: 16pt;">项目费用号：${
          this.parData.projectCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 110pt;font-size: 16pt;">供 应 商：${
          this.parData.suppliers || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 70pt;font-size: 16pt;">验 收 人：${
          this.parData.stockLeader || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 90pt;font-size: 16pt;">验收时间：${
          this.parData.inDate || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 130pt;font-size: 16pt;">库房：${
          this.parData.HOUSENAME || ""
        }</div>  
   
  </div>
  <table width="1000px" style="${
    this.title().title
  }border-collapse:collapse;margin-top: 160pt;" border="2px solid black" >
      <tr>
        <td width="50pt">序号</td>
        <td width="154pt">零件号</td>
        <td width="303pt">零件名称</td>
        <td width="70pt">试制数量</td>
        <td width="70pt">入库数量</td>
        <td width="100pt">采购员</td>
        <td width="150pt">库房</td>
      </tr>
    ${str}
    </table>
    </div>
  
</body>
</html><!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body>
  <div style="padding:1px;">
    <div style="${this.title().title}">
    <div style="${
      this.title().header
    }left: 40%; top: 5pt;font-size: 22pt; ">入库验收单</div>  
    <div style="${
      this.title().header
    }left: 60%; top: 40pt;font-size: 16pt; ">验收单号：${
          this.parData.code || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 70pt;font-size: 16pt;">试制任务单：${
          this.parData.stageCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left:20pt; top: 90pt;font-size: 16pt;">试制提单人：${
          this.parData.submitPerson || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 110pt;font-size: 16pt;">提单人电话：${
          this.parData.submitPersonPhone || ""
        }</div>  
    <div style="${
      this.title().header
    }left:270pt; top: 70pt;font-size: 16pt;">采购申请号：${
          this.parData.purchaseCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 90pt;font-size: 16pt;">项目费用号：${
          this.parData.projectCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 110pt;font-size: 16pt;">供 应 商：${
          this.parData.suppliers || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 70pt;font-size: 16pt;">验 收 人：${
          this.parData.stockLeader || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 90pt;font-size: 16pt;">验收时间：${
          this.parData.inDate || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 130pt;font-size: 16pt;">库房：${
          this.parData.HOUSENAME || ""
        }</div>   
   
  </div>
  <table width="1000px" style="${
    this.title().title
  }border-collapse:collapse;margin-top: 160pt;" border="2px solid black" >
      <tr>
        <td width="50pt">序号</td>
        <td width="154pt">零件号</td>
        <td width="303pt">零件名称</td>
        <td width="70pt">试制数量</td>
        <td width="70pt">入库数量</td>
        <td width="100pt">采购员</td>
        <td width="150pt">库房</td>
      </tr>
    ${str}
    </table>
    </div>
  
</body>
</html>`,
        options,
        function (e) {
          _this.$router.go(-1);
          console.log("success", e);
        },
        function (e) {
          _this.$router.go(-1);
          console.log("error", e);
        }
      );
    },
    title() {
      return {
        title: "width: 800pt; position: relative;",
        name: "margin: 0px;padding: 0px;overflow: hidden",
        header:
          "position: absolute; padding: 2pt; text-align: left; border: 0pt solid black; font-family: Microsoft YaHei; word-break: break-all; display: flex; align-items: center;",
        tableTd:
          "padding: 2pt; font-size: 14pt; text-align: left; border: 1pt solid black; font-family: Microsoft YaHei; word-break: break-all; display: flex; align-items: center; left: 14pt; top: 0pt; height: 20pt; width: 50pt; text-overflow: ellipsis; overflow: hidden;",
      };
    },
    drawCanvas(canvas, context, j, left) {
      var _this = this;

      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = _this.markList[j].codeUrl;
      //图片加载完后，将其显示在canvas中
      img.onload = function () {
        context.fillStyle = "#333";
        context.font = "20px bold Verdana";
        context.fillText("任务单号：" + _this.markList[j].stageCode, left, 245);
        context.fillText(
          "零件名称：" + _this.markList[j].PARTNAMECN,
          left,
          275
        ); //_this.markList[j].PARTNAMECN
        context.fillText("零件编号：" + _this.markList[j].partCode, left, 305);
        context.fillText(
          "零件版本：" +
            _this.markList[j].partVersion +
            "   " +
            "零件序号：" +
            _this.markList[j].partOrderNumber,
          left,
          335
        );
        // context.fillText(
        //   "零件序号：" + _this.markList[j].partOrderNumber,
        //   left,
        //   345
        // );
        context.drawImage(this, left + 112, 20, 200, 200);
        canvas.style.background = "rgb(255, 255, 255)";
      };
    },
    async printCanvas() {
      var _this = this;
      for (let i = 0; i < _this.markList.length; i++) {
        if (i % 2 == 0) {
          (async function (j) {
            var dataBase = document.getElementById(`canvas${j}`).toDataURL();
            setTimeout(() => {
              window.ZebraPrinterAndroid.PrintImageBase64(
                localStorage.getItem("printHttp"),
                localStorage.getItem("printPort"),
                dataBase.slice(dataBase.indexOf(",") + 1),
                function () {
                  console.log("success");
                },
                function () {
                  Utils.presentToastWarning("打印失败");
                  console.log("fail");
                }
              );
            }, j * 5000 + 1000);
          })(i);
        }
      }
    },
    addTaskHandler(val, idx) {
      this.idx = idx;
      if (val.stockLoacleIds && val.stockLoacleIds.indexOf("(") > -1) {
        var str = val.stockLoacleIds
          ? val.stockLoacleIds.substr(1, val.stockLoacleIds.length - 2)
          : "";

        this.stockLoacleIds = str.split(")(").join(",");
      } else {
        this.stockLoacleIds = val.stockLoacleIds || "";
      }
      this.stocks = val.localeCodes || "";
      menuController.enable(true, "StoreChoose");
      menuController.open("StoreChoose");
    },
    getTaskCode() {
      menuController.enable(true, "searchTask");
      menuController.open("searchTask");
    },
    //自制件选择零件
    selected(type) {
      console.log(this.stageId);
      if (!this.stageId) {
        Utils.presentToastTopWarning("请选择任务单", "warning");
        return false;
      }
      this.openModal();
    },
    transfer(type) {
      console.log("img");
      // this.$router.push({
      //   path: "/scan",
      // });
      this.showScan = true;
    },
    async delPart(item, idx) {
      const _this = this;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否删除该零件",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });

              await loading.present();

              deletePartBySelect({
                datas: [{ id: item.id }],
              }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.tableList.splice(idx, 1);
                  Utils.presentToastTopWarning("删除成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "warning"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    async commit() {
      const _this = this;

      let aalist = _this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true  })
      if (aalist.length == 0) {
        Utils.presentToastTopWarning("无可接收零件", "danger");
        return false;
      }
      if (_this.radioId == 1) {
        for (var i = 0; i < aalist.length; i++) {
          if (_this.tableList[i].arrivalNum == 0) {
            Utils.presentToastTopWarning(
              _this.tableList[i].partNameCN + "接收数量不能为0",
              "danger"
            );
            return false;
          }
        }
      } else if (
        _this.radioId == 2 &&
        aalist.some((e) => {
          return e.arrivalNum != 0;
        })
      ) {
      } else {
        Utils.presentToastTopWarning("无接收零件", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        subHeader: '本次入库 '+ aalist.length +' 种零件',
        message: "是否提交入库单",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              aalist.forEach((e) => {
                e.isCheck = e.isCheck ? 1 : 0;
              });

              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });

              await loading.present();
              _this.sendPush(loading);
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    sendPush(loading) {
      var _this = this;
      storagePublish({
        billId: _this.innerId,
        datas: _this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true }),
        isDelivery: _this.isDelivery,
      }).then((res) => {
        loading.dismiss();
        if (!res.data.isError && res.data.data.isSuccess) {
          Utils.presentToastTopWarning("提交成功", "success");
          _this.parData = res.data.data.bill;
          _this.test();
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "warning"
          );
        }
      });
    },
    choose(val, val1) {
      this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true })[this.idx].stockLoacleIds = val;
      this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true })[this.idx].localeCodes = val1;
      this.stockLoacleIds = val;
    },
    // 同步库位
    async syncStore() {
      var bool = this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true  }).some((e) => {
        return e.stockLoacleIds;
      });
      if (bool) {
        const _this = this;
        const alert = await alertController.create({
          cssClass: "my-custom-class",
          header: "提示",
          message: "是否同步",
          buttons: [
            {
              text: "取消",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "确定",
              handler: async () => {
                var list = _this.tableList.filter((e) => {
                  return e.stockLoacleIds;
                }).filter(x=> {return this.radioId == 2?x.checked == true:true  });

                _this.tableList.filter(x=> {return  this.radioId == 2?x.checked == true:true  }).map((e) => {
                  e.stockLoacleIds = list[list.length - 1].stockLoacleIds;
                  e.localeCodes = list[list.length - 1].localeCodes;
                  return e;
                });
                Utils.presentToastTopWarning("同步成功", "success");
              },
            },
          ],
          ionAlertDidPresent: (e) => {
            console.log(e);
          },
        });
        await alert.present();

        const { role } = await alert.onDidDismiss();
      } else {
        Utils.presentToastTopWarning(
          "请先维护库位",
          "danger"
        );
      }
    },
    selectTaskId() {},

    // 选择任务单
    chooseCode(e) {
      this.islist = true;
      this.buyer = e.buyer;
      this.stageId = e.stageId;
      let param = {
        id: this.innerId,
        stageId: e.stageId || "",
        inTypeId: this.radioId,
      };
      if (this.radioId == 1) {
        this.taskCode = e.stageCode;
      } else if (this.radioId == 2) {
        Object.assign(param, {
          purchaseCode: e.purchaseCode,
          purchaseBillId: e.id,
        });
        this.taskCode = e.purchaseCode;
        this.stageCode = e.stageCode;
      }
      getStorageItem(param).then((res) => {
        this.islist = false;

        if(!res.data.data.isSuccess){
          Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "warning"
                  );
                  this.tableList=[];
          return
        }
        this.tableList = res.data.data.resultData.map(x=>{x.checked = false; return x}) || [];
      });
    },
    // 更改接收数量
    changeNum(item, idx) {
      console.log(item);
      if (
        item.trialNum - item.inNumP < item.arrivalNum &&
        item.arrivalNum !== 0
      ) {
        this.tableList[idx].arrivalNum = item.trialNum - item.inNumP;
        Utils.presentToastTopWarning(
          "接收数量与入库数量总和不能大于采购数量",
          "danger"
        );
      }
      if (!item.isCheck) {
        this.tableList[idx].inNum = item.arrivalNum;
      }
    },
    marking() {
      const data = {
        billId: this.innerId,
        type: 9,
        width: 300,
        height: 300,
        contents: this.tableList.filter((e) => {
          return e.isCheck == true || e.isCheck == 1;
        }).filter(x=> {return this.radioId == 2?x.checked == true:true  }),
      };
      console.log(this.tableList);
      if (
        !localStorage.getItem("printHttp") &&
        !localStorage.getItem("printPort")
      ) {
        Utils.presentToastTopWarning("当前设备没有设置打印机", "danger");
        return false;
      }
      if (data.contents.length == 0) {
        Utils.presentToastTopWarning("没有可以打印二维码的零件", "danger");
        return;
      }
      createTwoCode(data).then(async (res) => {
        console.log(res.data.data.resultData[0].codeUrl);
        // this.openPicModal(res.data.data.resultData);
        this.markList = res.data.data.resultData;
        this.pushOptions();
        // 这里做打印操作
        // blueConnect.manageConnection();
        // const response = await fetch(res.data.data.resultData[0].codeUrl);
        // const blob = await response.blob();
        // base64Data = await convertBlobToBase64(blob);
        // console.log(base64Data);
      });
    },

    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsArrayBuffer(blob);
      });
    },

    closeScan(contents) {
      this.showScan = false;
      if (contents.id && contents.purchaseCode) {
        this.taskCode = contents.purchaseCode;
        this.chooseCode(contents);
      } else if (contents.id && contents.localeCode) {
        if (this.tableList.length > 0) {
          if (this.stockLoacleIds.indexOf(contents.id) == -1) {
            var list = this.stockLoacleIds
              ? this.stockLoacleIds.split(",")
              : [];
            list.push(contents.id);
            var list1 = this.tableList[this.idx].localeCodes
              ? this.tableList[this.idx].localeCodes.split(",")
              : [];
            list1.push(contents.localeCode);
            this.choose(list.join(","), list1.join(","));
          } else {
            Utils.presentToastTopWarning("此零件已上架该库位", "danger");
          }
        } else {
          Utils.presentToastTopWarning("请先扫描采购单二维码", "danger");
        }
      } else {
        Utils.presentToastTopWarning("请正确扫描二维码", "danger");
      }
    },
    async openPicModal(item) {
      var _this = this;
      const modal = await modalController.create({
        component: picModel,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
        },
      });
      modal.present();
    },
    async openModal() {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });

      await loading.present();
      var data = await getPartBySelect({
        id: this.innerId,
        stageId: this.stageId,
      });
      loading.dismiss();
      var _this = this;
      const modal = await modalController.create({
        component: receiveSelectParts,
        cssClass: "my-custom-class",
        componentProps: {
          item: data.data.data.resultData,
          id: _this.innerId,
          stageId: _this.stageId,
        },
      });
      modal.present();
      var data = await modal.onDidDismiss();
      if (!data.data) {
        return false;
      }
      this.tableList = data.data.list;
    },
    async storeReview(item, idx) {
      var _this = this;
      const modal = await modalController.create({
        component: storeReview,
        cssClass: "my-custom-class",
        componentProps: {
          localeCodes: item.localeCodes,
          stockLoacleIds: item.stockLoacleIds,
        },
      });
      modal.present();
      var data = await modal.onDidDismiss();
      if (!data.data) {
        return false;
      }
      var list = data.data.list;
      var list1 = [];
      var list2 = [];
      list.forEach((e) => {
        list1.push(e.id);
        list2.push(e.str);
      });
      this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true  })[idx].stockLoacleIds = list1.join(",");
      this.tableList.filter(x=> {return this.radioId == 2?x.checked == true:true  })[idx].localeCodes = list2.join(",");
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
  .right > div {
    white-space: nowrap;
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  position: relative;
  .delPart {
    position: absolute;
    right: -5px;
    top: -5px;
  }
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:  px2rem(88);
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
.checkboxText {
  vertical-align: middle;
  white-space: nowrap;
}
input {
  vertical-align: middle;
}
.selectBtn {
  margin: 0 auto;
  width: 100%;
  height: 30px;
}
.canvasContent {
  display: none;
}

.card-content {
  width: calc(100% - 24px);
  padding: 0 15px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 5px 18px 0px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
   > div {
    padding: 15px 0 15px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      justify-content: start;
      align-items: center;
      .questionTitle {
        font-size: 13px;
        font-family: Tensentype ChaoHeiJ, Tensentype ChaoHeiJ-Bold;
        font-weight: 700;
        color: #333333;
      }
      .identifier {
        font-size: 14px;
        font-family: DIN, DIN-Medium;
        font-weight: bold;
        text-align: left;
        color: #333333;
      }
      .imgIcon {
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
      ion-checkbox {
        margin-right: 11px;
      }
    }

    .part {
      font-size: 11px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: bold;
      text-align: left;
      color: #333333;
    }
  }
  > div:nth-of-type(1) {
    border-bottom: 0.5px solid #efefef;
  }
}
</style>