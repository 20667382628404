<template>
  <div class="card-content">
    <div>
      <div>
        <ion-checkbox color="light" v-model="part.checked"></ion-checkbox>
        <ion-text class="questionTitle">{{ part.partCode }}</ion-text>
      </div>
      <div>
        <ion-text class="questionTitle">{{ part.partNameCN }}</ion-text>
      </div>
      <div>
        <ion-img
          class="imgIcon"
          :src="require('@/assets/images/identifier.png')"
        ></ion-img>
        <ion-text class="identifier">{{ part.partVersion }}</ion-text>
      </div>
    </div>
    <div>
      <ion-text class="part">库存</ion-text>
      <ion-text class="part">{{ part.stockNum }}</ion-text>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  methods: {
    change() {
      console.log(111);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-content {
  width: calc(100% - 24px);
  padding: 0 15px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 3px 5px 18px 0px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
   > div {
    padding: 15px 0 15px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      justify-content: start;
      align-items: center;
      .questionTitle {
        font-size: 13px;
        font-family: Tensentype ChaoHeiJ, Tensentype ChaoHeiJ-Bold;
        font-weight: 700;
        color: #333333;
      }
      .identifier {
        font-size: 14px;
        font-family: DIN, DIN-Medium;
        font-weight: bold;
        text-align: left;
        color: #333333;
      }
      .imgIcon {
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
      ion-checkbox {
        margin-right: 11px;
      }
    }

    .part {
      font-size: 11px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: bold;
      text-align: left;
      color: #333333;
    }
  }
  > div:nth-of-type(1) {
    border-bottom: 0.5px solid #efefef;
  }
}
</style>