<template >
  <Header
    type="0"
    title="零件列表"
    :isBack="false"
    :btnIconRight="['icon-guanbi']"
    @transfer="transfer"
  >
  </Header>
  <ion-content class="ion-padding" id="ProjectMainProjectList">
    <parts-card :part="formData" v-for="formData in item" :key="formData.id" />
  </ion-content>
  <ion-footer>
    <ion-button expand="block" @click="commit">保存</ion-button>
  </ion-footer>
</template>
<script>
import { defineComponent } from "vue";
import {
  modalController,
  menuController,
  navParames,
  loadingController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils.ts";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import PartsCard from "./partsCard.vue";
import { doSavePartBySelect } from "@/api/storeManagement/warehouseReceiving";
export default defineComponent({
  components: {
    Header,
    CardLoading,
    PartsCard,
  },
  props: {
    item: Array,
    id:[String,Number],
    stageId:[String,Number]
  },
  data() {
    return {};
  },
  methods: {
    transfer() {
      modalController.dismiss();
    },
    async commit() {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });

      await loading.present();
      var list = []
      list=this.item.filter(e=>{
          return e.checked
      })
      if(list.length==0){
        loading.dismiss();
          Utils.presentToastTopWarning("请选择零件", "danger");
          return false;
      }
      doSavePartBySelect({id:this.id,stageId:this.stageId,datas:list}).then((res) => {
          modalController.dismiss({
            list:res.data.data.resultData||[]
          });
        loading.dismiss();
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.app-container {
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;

  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
</style>